import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificadoService } from 'src/app/services/certificado.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.sass'],
})
export class SuccessComponent implements OnInit {
  comprobante: string | null;
  nroComp: number | null;
  importe: number | null;
  title: string = '';
  constructor(
    private acrivatedRoute: ActivatedRoute,
    private readonly service: CertificadoService,
    private router: Router
  ) {
    this.comprobante =
      this.acrivatedRoute.snapshot.queryParamMap.get('comprobante');
    this.nroComp = parseInt(
      this.acrivatedRoute.snapshot.queryParamMap.get('nroComp')!
    );
    this.importe = parseInt(
      this.acrivatedRoute.snapshot.queryParamMap.get('importe')!
    );
  }

  ngOnInit(): void {
    this.service
      .processPayment(this.comprobante!, this.nroComp!, this.importe!)
      .subscribe((response: any) => {
        console.log(response);
        if (response['dsTablas']['tt-response'][0]['tsucces']) {
          this.title = response.dsTablas['tt-response'][0]['tobs'];
        } else {
          this.router.navigateByUrl('/fail');
        }
      });
  }
}
