<app-navbar></app-navbar>

<div class="container">
  <div class="col-md-12">
    <div class="jumbotron mx-auto mt-5" style="max-width: 400px;">
      <h1 class="display-4">Muchas Gracias!</h1>
      <p class="lead">{{title | titlecase}}</p>
      <hr class="my-4" />
      <p>Haga click en continuar para volver atras</p>
      <p class="lead text-center">
        <a class="btn btn-primary btn-sm" href="#" role="button">Continuar</a>
      </p>
    </div>
  </div>
</div>
