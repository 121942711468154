import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CertificadoService {
  constructor(private http: HttpClient) {}

  get(data: any) {
    return this.http.post(environment.certificado, { 'tt-datos': [data] });
  }
  toggle(): Observable<boolean> {
    return this.http.get<boolean>(environment.toggle);
  }

  processPayment(
    codComprobante: string,
    nroComprobante: number,
    importe: number
  ) {
    return this.http.post(environment.paga, {
      'tt-datos': [
        {
          ttcodcomprob: codComprobante,
          ttnrocomprob: nroComprobante,
          ttimporte: importe,
        },
      ],
    });
  }
}
