<app-navbar></app-navbar>

<div class="container">
  <div class="col-md-6 col-sm-12 mx-auto">
    <br />
    <h3>Mis Facturas</h3>
    <form [formGroup]="form" novalidate>
      <div class="form-group mb-3">
        <label for="">Plan</label>
        <input
          formControlName="tPlan"
          class="form-control rounded-0"
          placeholder="Plan"
        />
      </div>
      <div class="form-group mb-3">
        <label for="">Certificado</label>
        <input
          formControlName="tCertificado"
          class="form-control rounded-0"
          placeholder="Certificado"
          type="number"
        />
      </div>
      <div class="form-group mb-3">
        <label for="">Documento</label>
        <input
          formControlName="tNroDoc"
          class="form-control rounded-0"
          placeholder="Documento"
          type="number"
        />
      </div>
      <div>
        <re-captcha
          (resolved)="resolved($event)"
          siteKey="6LeP_rohAAAAALQtup9Byfb-CdicIAfomg3ftI18"
        ></re-captcha>
      </div>
      <div class="text-center mt-3">
        <button
          type="submit"
          class="btn btn-primary rounded-0"
          [disabled]="form.invalid"
          (click)="get(form.value)"
        >
          Consultar
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="facturas" class="col-md-6 col-sm-12 mx-auto">
    <br />
    <h3>Coberturas</h3>
    <hr />
    <table class="table">
      <thead>
        <th>Fecha</th>
        <th>Estado</th>
        <th>PDF</th>
        <th></th>
      </thead>
      <tbody>
        <tr *ngFor="let cobertura of facturas">
          <td
            data-toggle="tooltip"
            data-placement="top"
            [title]="cobertura.ttdeudamacro"
            style="cursor: pointer"
          >
            {{ cobertura.tcobertura }}
          </td>
          <td>
            <span
              *ngIf="cobertura.ttdeuda <= 0"
              class="badge badge-success"
              style="color: #fff; background-color: #28a745"
              >Pagada</span
            >
            <span
              *ngIf="cobertura.ttdeuda > 0"
              class="badge badge-success"
              style="color: #fff; background-color: #e4dd15"
              >Pendiente</span
            >
          </td>
          <td>
            <a [href]="cobertura.tpdf" target="_blank">PDF</a>
          </td>
          <td>
            <form
              method="POST"
              action="https://sandboxpp.asjservicios.com.ar"
              id="form-firma"
            >
              <input
                hidden
                type="text"
                name="CallbackSuccess"
                id="CallbackSuccess"
                [value]="
                  AESEncrypter.encryptString(
                    'https://mifacturadesa.previncasalud.com.ar/development/#/success?comprobante=' +
                      cobertura.ttcodcomprob +
                      '&nroComp=' +
                      cobertura.ttnrocomprob +
                      '&importe=' +
                      cobertura.ttdeudamacro,
                    secretKey
                  )
                "
              />
              <input
                hidden
                type="text"
                name="CallbackCancel"
                id="”CallbackCancel”"
                [value]="callbackCancel"
              />
              <input
                hidden
                type="text"
                name="SucursalComercio"
                id="SucursalComercio"
                [value]="sucursalComercio"
              />
              <input
                hidden
                type="text"
                name="TransaccionComercioId"
                id="TransaccionComercioId"
                [value]="transaccionComercioId"
              />
              <input
                hidden
                type="text"
                name="Comercio"
                id="Comercio"
                [value]="'2fdd4a42-61a2-463d-8ef6-99486f29bfb1'"
              />
              <input
                hidden
                type="text"
                name="Producto[0]"
                id="Producto[0]"
                [value]="
                  cobertura.ttnrocomprob +
                  '-' +
                  cobertura.ttcodcomprob +
                  '-' +
                  cobertura.ttdeudamacro
                "
              />
              <input
                hidden
                type="text"
                name="Monto"
                id="Monto"
                [value]="
                  AESEncrypter.encryptString(cobertura.ttdeudamacro, secretKey)
                "
              />
              <button
                style="
                  all: unset;
                  cursor: pointer;
                  text-decoration: underline;
                  color: #0d6efd;
                "
                *ngIf="cobertura.ttpuedepagar"
                type="submit"
              >
                Pagar
              </button>
            </form>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
