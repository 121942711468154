// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  certificado:
    'https://webmail.previncasalud.com.ar:4433//cgi-bin/wspdprex/WService=desa7893K2/prueba01macro.html',
  paga: 'https://webmail.previncasalud.com.ar:4433//cgi-bin/wspdprex/WService=desa7893K2/pagamacro.html',
  secretKey: 'PREVINCASRL_ec5567cc-4679-40fc-912a-3394b4306cb1',
  callbackSucces: 'http://localhost:4202/success',
  callbackCancel: 'https://mifacturadesa.previncasalud.com.ar/development/fail',
  sucursalComercio: '2fdd4a42-61a2-463d-8ef6-99486f29bfb1',
  url: 'https://sandboxpp.asjservicios.com.ar',
  toggle: 'https://mifactura.previncasalud.com.ar/20220709',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
