import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CertificadoService } from 'src/app/services/certificado.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

declare const AESEncrypter: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
})
export class HomeComponent implements OnInit {
  form: UntypedFormGroup;
  facturas: any;
  AESEncrypter = AESEncrypter;
  secretKey = environment.secretKey;
  callbackSuccess = environment.callbackSucces;
  callbackCancel = environment.callbackCancel;
  transaccionComercioId = '';
  sucursalComercio = '';
  toggle: boolean = false;

  constructor(
    private cert: CertificadoService,
    private builder: UntypedFormBuilder,
    private toastr: ToastrService
  ) {
    this.callbackSuccess = AESEncrypter.encryptString(
      this.callbackSuccess,
      this.secretKey
    );
    this.callbackCancel = AESEncrypter.encryptString(
      this.callbackCancel,
      this.secretKey
    );

    this.transaccionComercioId = AESEncrypter.encryptString(
      this.transaccionComercioId,
      this.secretKey
    );

    this.sucursalComercio = AESEncrypter.encryptString(
      this.sucursalComercio,
      this.secretKey
    );

    this.form = this.builder.group({
      tCertificado: [, Validators.required],
      tCodDoc: [1, Validators.required],
      tNroDoc: [, Validators.required],
      tPlan: [, Validators.required],
      recaptcha: [false, Validators.requiredTrue],
    });

    this.cert.toggle().subscribe((response) => {
      console.log(response);
      this.toggle = response;
    });
  }

  ngOnInit(): void {}

  resolved(event: any) {
    this.form.controls['recaptcha'].setValue(true);
  }

  get(data: any) {
    this.cert.get(data).subscribe(
      (c: any) => {
        if (!c.dsTablas['tt-response'][0]['tsucces']) {
          this.toastr.error(c.dsTablas['tt-response'][0]['tobs']);
        }
        this.facturas = c.dsTablas['tt-coberturas'];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
